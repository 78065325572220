import { NavLink } from 'react-router-dom';
import auth from '../../utils/auth';

function NavItem({ to, icon, label, admin, investor }) {
    return (
        admin && auth.type() !== auth.ADMIN
        ?
        null
        :
        investor && auth.type() !== auth.INVESTOR
        ?
        null
        :
        <li className='nav-item' data-bs-dismiss='offcanvas'>
            <NavLink to={to} className={`nav-link ${({ isActive }) => isActive ? 'active' : ''}`} aria-current={({ isActive }) => isActive ? 'page' : 'false'}>
                <i className={`icon icon-${icon} icon-lg align-middle`}></i>
                <strong className='align-middle'>{label}</strong>
            </NavLink>
        </li>
    )
}

export default NavItem;