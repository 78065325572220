import { useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Navbar from './components/partials/Navbar';
import routes from './utils/routes';
import svg from './utils/svg';
import analytics from './utils/analytics';
import './App.scss';

function App() {
    const location = useLocation();
    const { id, name } = useParams();
    
    useEffect(() => {
        analytics.init();
    }, []);

    useEffect(() => {
        let route = (routes.pub.concat(routes.dashboard)).find(i => {
            let path = i.path;
            if (name) {
                path = path.replace(':name', name);
            } else if (id) {
                path = path.replace(':id', id);
            }
            return path === location.pathname;
        });
        if (location.pathname === '/') {
            document.title = `${process.env.REACT_APP_NAME} | ${route.title}`;
        } else {
            document.title = `${route.title} | ${process.env.REACT_APP_NAME}`;
        }
        analytics.view(location.pathname);
    }, [location, name]);

    return (
        <div className='d-flex flex-column w-100 vh-100'>
            <Navbar />
            <div dangerouslySetInnerHTML={{ __html: svg.defs }} style={{ height: 0 }}></div>
            <div id="container" className='d-flex flex-grow-1 overflow-auto'>
                <div className='w-100'>
                    <div className='py-5'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
