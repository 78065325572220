import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './utils/routes';
import NotFound from './components/NotFound';
import PublicRoute from './components/plugins/PublicRoute';
import ProtectedRoute from './components/plugins/ProtectedRoute';
import auth from './utils/auth';
import App from './App';

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path='/' element={<App />}>
                    {routes.pub.map(i =>
                        <Route key={i.path} path={i.path} element={<PublicRoute>{i.element}</PublicRoute>} />
                    )}
                    {routes.dashboard.map(i =>
                        i.admin && auth.type() !== auth.ADMIN
                        ?
                        null
                        :
                        i.investor && auth.type() !== auth.INVESTOR
                        ?
                        null
                        :
                        <Route key={i.path} path={i.path} element={<ProtectedRoute>{i.element}</ProtectedRoute>} />
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
