import NavItem from '../plugins/NavItem';
import 'bootstrap/js/dist/offcanvas';
import { NavLink } from 'react-router-dom';

function NavPublic() {
    return (
        <ul className='navbar-nav'>
            <NavItem to='/' icon='home' label='Home' />
            <NavItem to='/about' icon='info' label='About' />
            <NavItem to='/insights' icon='eyeglasses' label='Insights' />
            <li className='nav-item' data-bs-dismiss='offcanvas'>
                <a className='nav-link' href='https://docs.sofven.com/' target='_blank' rel='noreferrer'>
                    <i className='icon icon-file icon-lg align-middle'></i>
                    <strong className='align-middle'>Whitepaper</strong>
                </a>
            </li>
            <li className='nav-item ms-auto mt-auto d-lg-none' data-bs-dismiss='offcanvas'>
                <NavLink to='/' className='btn btn-primary w-100'>Login</NavLink>
            </li>
        </ul>
    );
}

export default NavPublic;