import './Loader.scss';

const svg = `
    <svg width='521px' height='521px' viewBox='0 0 521 521' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
        <title>Bamboo Logo</title>
        <g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
            <g id='IG-Story' transform='translate(-239.000000, -239.000000)'>
                <g id='Bamboo-Logo' transform='translate(245.000000, 245.000000)'>
                    <g id='Group'>
                        <circle id='circleInner' class='circleInner' stroke='url(#linearGradientCircle)' stroke-width='7' cx='254.5' cy='254.5' r='254.5'></circle>
                        <circle id='circleOuter' class='circleOuter' stroke='#06DDF4' stroke-width='11' cx='254.5' cy='254.5' r='254.5'></circle>
                    </g>
                    <g id='Bamboo-1' transform='translate(149.000000, 211.649428)' fill-rule='nonzero'>
                        <g id='Path' class='pathBamboo1Top'>
                            <use fill='black' fill-opacity='1' filter='url(#filterBamboo1Top)' xlink:href='#pathBamboo1Top'></use>
                            <use fill='url(#linearGradientBamboo1Top)' xlink:href='#pathBamboo1Top'></use>
                        </g>
                        <g id='Rectangle' class='pathBamboo1Bottom'>
                            <use fill='black' fill-opacity='1' filter='url(#filterBamboo1Bottom)' xlink:href='#pathBamboo1Bottom'></use>
                            <use fill='url(#linearGradientBamboo1Bottom)' xlink:href='#pathBamboo1Bottom'></use>
                        </g>
                    </g>
                    <g id='Bamboo-2' transform='translate(220.238095, 149.621968)' fill-rule='nonzero'>
                        <g id='Path' class='pathBamboo2Top'>
                            <use fill='black' fill-opacity='1' filter='url(#filterBamboo2Top)' xlink:href='#pathBamboo2Top'></use>
                            <use fill='url(#linearGradientBamboo2Top)' xlink:href='#pathBamboo2Top'></use>
                        </g>
                        <g id='Rectangle' class='pathBamboo2Bottom'>
                            <use fill='black' fill-opacity='1' filter='url(#filterBamboo2Bottom)' xlink:href='#pathBamboo2Bottom'></use>
                            <use fill='url(#linearGradientBamboo2Bottom)' xlink:href='#pathBamboo2Bottom'></use>
                        </g>
                    </g>
                    <g id='Bamboo-3' transform='translate(287.285714, 100.000000)' fill-rule='nonzero'>
                        <g id='Path' class='pathBamboo3Top'>
                            <use fill='black' fill-opacity='1' filter='url(#filterBamboo3Top)' xlink:href='#pathBamboo3Top'></use>
                            <use fill='url(#linearGradientBamboo3Top)' xlink:href='#pathBamboo3Top'></use>
                        </g>
                        <g id='Rectangle' class='pathBamboo3Middle'>
                            <use fill='black' fill-opacity='1' filter='url(#filterBamboo3Middle)' xlink:href='#pathBamboo3Middle'></use>
                            <use fill='url(#linearGradientBamboo3Middle)' xlink:href='#pathBamboo3Middle'></use>
                        </g>
                        <g id='Rectangle' class='pathBamboo3Bottom'>
                            <use fill='black' fill-opacity='1' filter='url(#filterBamboo3Bottom)' xlink:href='#pathBamboo3Bottom'></use>
                            <use fill='url(#linearGradientBamboo3Bottom)' xlink:href='#pathBamboo3Bottom'></use>
                        </g>
                    </g>
                    <g id='Leaves' transform='translate(345.952381, 108.270328)' fill-rule='nonzero'>
                        <g id='Path' class='pathLeavesTop'>
                            <use fill='black' fill-opacity='1' filter='url(#filterLeavesTop)' xlink:href='#pathLeavesTop'></use>
                            <use fill='url(#linearGradientLeavesTop)' xlink:href='#pathLeavesTop'></use>
                        </g>
                        <g id='Path' class='pathLeavesBottom'>
                            <use fill='black' fill-opacity='1' filter='url(#filterLeavesBottom)' xlink:href='#pathLeavesBottom'></use>
                            <use fill='#FFFFFF' xlink:href='#pathLeavesBottom'></use>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
`;

function Loader({ className }) {
    return (
        <div className={`loader ${className ? className : ''}`} dangerouslySetInnerHTML={{ __html: svg }}></div>
    );
}

export default Loader;