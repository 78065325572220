
import { NavLink } from 'react-router-dom';
import Navbar from './partials/Navbar';

function NotFound() {
    return (
        <div className='d-inline-flex flex-column w-100 vh-100'>
            <Navbar />
            <div className='d-flex flex-grow-1 overflow-auto'>
                <div className='container-fluid overflow-auto py-5'>
                    <div className='row justify-content-center'>
                        <div className='col-md-6'>
                            <h1 className='mb-5'>Page not found.</h1>
                            <NavLink to='/'>
                                &larr; Go Back Home
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;