import { useNavigate } from 'react-router-dom';
import NavItem from '../plugins/NavItem';
import auth from '../../utils/auth';
import 'bootstrap/js/dist/offcanvas';

function NavDashboard() {
    const navigate = useNavigate();

    const handleLogout = () => {
        auth.logout();
        navigate('/', { replace: true });
    };

    return (
        <ul className='navbar-nav align-items-center w-100'>
            <NavItem to='/dashboard/home' icon='home' label='Portfolio' />
            <NavItem to='/dashboard/transactions' icon='suitcase' label='Transactions' />
            <NavItem to='/dashboard/users' icon='users' label='Users' admin={true} />
            <NavItem to='/dashboard/constants' icon='gear' label='Constants' admin={true} />
            <NavItem to='/dashboard/investor' icon='user' label='Investor' investor={true} />
            <NavItem to='/dashboard/profile' icon='profile' label='Profile' />
            <NavItem to='/dashboard/files' icon='document' label='Files' />
            <NavItem to='/dashboard/insights' icon='eyeglasses' label='Insights' admin={true} />
            <li className='nav-item ms-auto mt-auto' data-bs-dismiss='offcanvas'>
                <button type='button' className='btn btn-primary w-100' onClick={handleLogout}>Log Out</button>
            </li>
        </ul>
    );
}

export default NavDashboard;