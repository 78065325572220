const ADMIN = 'admin';
const INVESTOR = 'investor';

const login = async (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
}

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}

const check = () => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    return token !== null && user !== null;
}

const user = () => {
    const user = localStorage.getItem('user');
    return user !== null ? JSON.parse(user) : null;
}

const type = () => {
    if (check()) {
        return user().type;
    }
    return '';
}

const auth = {
    ADMIN,
    INVESTOR,
    login,
    logout,
    check,
    user,
    type,
};

export default auth;