import React, { Suspense } from 'react';
import Loader from '../components/plugins/Loader';

const Welcome = React.lazy(() => import('../components/Welcome'));
const About = React.lazy(() => import('../components/About'));
const Posts = React.lazy(() => import('../components/Posts'));
const Post = React.lazy(() => import('../components/Post'));
const ForgotPassword = React.lazy(() => import('../components/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../components/ResetPassword'));

const Dashboard = {
    Home: React.lazy(() => import('../components/dashboard/Home')),
    Transactions: React.lazy(() => import('../components/dashboard/Transactions')),
    Coin: React.lazy(() => import('../components/dashboard/Coin')),
    Users: React.lazy(() => import('../components/dashboard/Users')),
    Constants: React.lazy(() => import('../components/dashboard/Constants')),
    Profile: React.lazy(() => import('../components/dashboard/Profile')),
    Investor: React.lazy(() => import('../components/dashboard/Investor')),
    Files: React.lazy(() => import('../components/dashboard/Files')),
    Posts: React.lazy(() => import('../components/dashboard/Posts')),
    Post: React.lazy(() => import('../components/dashboard/Post')),
};

const pub = [
    {
        path: '/',
        element: <Suspense fallback={<Loader />}><Welcome /></Suspense>,
        title: 'Capital',
    },
    {
        path: '/about',
        element: <Suspense fallback={<Loader />}><About /></Suspense>,
        title: 'About',
    },
    {
        path: '/insights',
        element: <Suspense fallback={<Loader />}><Posts /></Suspense>,
        title: 'Insights',
    },
    {
        path: '/insights/:name',
        element: <Suspense fallback={<Loader />}><Post /></Suspense>,
        title: 'Insights',
    },
    {
        path: '/forgot-password',
        element: <Suspense fallback={<Loader />}><ForgotPassword /></Suspense>,
        title: 'Forgot Password',
    },
    {
        path: '/reset-password',
        element: <Suspense fallback={<Loader />}><ResetPassword /></Suspense>,
        title: 'Reset Password',
    },
];

const dashboard = [
    {
        path: '/dashboard/home',
        element: <Suspense fallback={<Loader />}><Dashboard.Home /></Suspense>,
        title: 'Dashboard',
    },
    {
        path: '/dashboard/transactions',
        element: <Suspense fallback={<Loader />}><Dashboard.Transactions /></Suspense>,
        title: 'Transactions',
    },
    {
        path: '/dashboard/transactions/:name',
        element: <Suspense fallback={<Loader />}><Dashboard.Coin /></Suspense>,
        title: 'Transaction Overview',
    },
    {
        path: '/dashboard/users',
        element: <Suspense fallback={<Loader />}><Dashboard.Users /></Suspense>,
        title: 'Users',
        admin: true,
    },
    {
        path: '/dashboard/constants',
        element: <Suspense fallback={<Loader />}><Dashboard.Constants /></Suspense>,
        title: 'Constants',
        admin: true,
    },
    {
        path: '/dashboard/investor',
        element: <Suspense fallback={<Loader />}><Dashboard.Investor /></Suspense>,
        title: 'Investor',
        investor: true,
    },
    {
        path: '/dashboard/profile',
        element: <Suspense fallback={<Loader />}><Dashboard.Profile /></Suspense>,
        title: 'Profile',
    },
    {
        path: '/dashboard/files',
        element: <Suspense fallback={<Loader />}><Dashboard.Files /></Suspense>,
        title: 'Files',
    },
    {
        path: '/dashboard/insights',
        element: <Suspense fallback={<Loader />}><Dashboard.Posts /></Suspense>,
        title: 'Insights',
    },
    {
        path: '/dashboard/insights/:id',
        element: <Suspense fallback={<Loader />}><Dashboard.Post /></Suspense>,
        title: 'Insight',
    },
];

const routes = {
    pub,
    dashboard,
};

export default routes;