import ReactGA from 'react-ga4';

const init = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

const view = (page) => {
    ReactGA.send({ hitType: 'pageview', page });
}

const login = (label) => {
    ReactGA.event({
        category: 'form',
        action: 'Login',
        label,
    });
}

const forgot = () => {
    ReactGA.event({
        category: 'form',
        action: 'Submit Forgot Password',
    });
}

const reset = () => {
    ReactGA.event({
        category: 'form',
        action: 'Submit Reset Password',
    });
}

const profile = () => {
    ReactGA.event({
        category: 'form',
        action: 'Submit Profile',
    });
}

const interval = (label) => {
    ReactGA.event({
        category: 'button',
        action: 'Clicked Interval Button',
        label,
    });
}

const line = () => {
    ReactGA.event({
        category: 'button',
        action: 'Clicked Line Chart',
    });
}

const pie = () => {
    ReactGA.event({
        category: 'button',
        action: 'Clicked Pie Chart',
    });
}

const price = () => {
    ReactGA.event({
        category: 'button',
        action: 'Clicked Price Chart',
    });
}

const download = (label) => {
    ReactGA.event({
        category: 'download',
        action: 'Download File',
        label,
    });
}

const filter = (label) => {
    ReactGA.event({
        category: 'filter',
        action: 'Filter Transactions',
        label,
    });
}

const analytics = {
    view,
    init,
    login,
    forgot,
    reset,
    profile,
    interval,
    line,
    pie,
    price,
    download,
    filter,
}

export default analytics;