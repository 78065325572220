import NavPublic from './NavPublic';
import NavDashboard from './NavDashboard';
import imgLogo from '../../img/logo.svg';
import auth from '../../utils/auth';
import 'bootstrap/js/dist/offcanvas';
import './Navbar.scss';

function Navbar() {
    return (
        <nav id='navbar' className='navbar navbar-expand-lg navbar-dark flex-shrink-0 fade-left'>
            <div className='container-fluid'>
                <button className='navbar-toggler' type='button' data-bs-toggle='offcanvas' data-bs-target='#offcanvas' aria-controls='offcanvas'>
                    <span className='navbar-toggler-icon'></span>
                </button>
                <a href='/' className='navbar-brand'>
                    <img src={imgLogo} alt='' className='logo-shadow' width='45' height='45' /><strong className='align-middle d-none d-lg-inline-block ms-3'>Sofven</strong>
                </a>
                <div className='offcanvas offcanvas-start border-start' tabIndex='-1' id='offcanvas'>
                    <div className='offcanvas-header'>
                        <button type='button' className='btn-close text-reset' data-bs-dismiss='offcanvas' aria-label='Close'></button>
                    </div>
                    <div className='offcanvas-body'>
                        {
                            auth.check()
                            ?
                            <NavDashboard />
                            :
                            <NavPublic />
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;