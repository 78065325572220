const defs = `<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <defs>
        <linearGradient x1='70.9536867%' y1='76.2426848%' x2='8.70963498%' y2='0%' id='linearGradientCircle'>
            <stop stop-color='#040F1D' offset='0%'></stop>
            <stop stop-color='#1C71BE' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='41.2615826%' y1='0%' x2='41.2615826%' y2='244.322675%' id='linearGradientBamboo1Top'>
            <stop stop-color='#FFFFFF' offset='0%'></stop>
            <stop stop-color='#273C4B' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='41.2615826%' y1='0%' x2='41.2615826%' y2='181.374183%' id='linearGradientBamboo1Bottom'>
            <stop stop-color='#FFFFFF' offset='0%'></stop>
            <stop stop-color='#273C4B' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='41.2615826%' y1='0%' x2='41.2615826%' y2='362.319994%' id='linearGradientBamboo2Top'>
            <stop stop-color='#FFFFFF' offset='0%'></stop>
            <stop stop-color='#273C4B' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='41.2615826%' y1='0%' x2='41.2615826%' y2='195.976712%' id='linearGradientBamboo2Bottom'>
            <stop stop-color='#FFFFFF' offset='0%'></stop>
            <stop stop-color='#273C4B' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='41.2615826%' y1='0%' x2='41.2615826%' y2='458.65746%' id='linearGradientBamboo3Top'>
            <stop stop-color='#FFFFFF' offset='0%'></stop>
            <stop stop-color='#273C4B' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='41.2615826%' y1='0%' x2='41.2615826%' y2='328.422529%' id='linearGradientBamboo3Middle'>
            <stop stop-color='#FFFFFF' offset='0%'></stop>
            <stop stop-color='#273C4B' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='41.2615826%' y1='0%' x2='41.2615826%' y2='237.651645%' id='linearGradientBamboo3Bottom'>
            <stop stop-color='#FFFFFF' offset='0%'></stop>
            <stop stop-color='#273C4B' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='58.3156819%' y1='45.7776923%' x2='-138.960677%' y2='183.4941%' id='linearGradientLeavesTop'>
            <stop stop-color='#FFFFFF' offset='0%'></stop>
            <stop stop-color='#273C4B' offset='100%'></stop>
        </linearGradient>
        <filter x='-50.5%' y='-19.6%' width='224.8%' height='148.5%' filterUnits='objectBoundingBox' id='filterBamboo1Top'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <filter x='-50.5%' y='-44.3%' width='224.8%' height='209.6%' filterUnits='objectBoundingBox' id='filterBamboo1Bottom'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <filter x='-50.5%' y='-20.2%' width='224.8%' height='149.8%' filterUnits='objectBoundingBox' id='filterBamboo2Top'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <filter x='-50.5%' y='-21.5%' width='224.8%' height='153.1%' filterUnits='objectBoundingBox' id='filterBamboo2Bottom'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <filter x='-50.5%' y='-26.6%' width='224.8%' height='165.8%' filterUnits='objectBoundingBox' id='filterBamboo3Top'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <filter x='-50.5%' y='-19.6%' width='224.8%' height='148.4%' filterUnits='objectBoundingBox' id='filterBamboo3Middle'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <filter x='-50.5%' y='-44.3%' width='224.8%' height='209.6%' filterUnits='objectBoundingBox' id='filterBamboo3Bottom'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <filter x='-41.0%' y='-55.4%' width='201.4%' height='237.0%' filterUnits='objectBoundingBox' id='filterLeavesTop'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <filter x='-54.7%' y='-132.8%' width='235.2%' height='428.7%' filterUnits='objectBoundingBox' id='filterLeavesBottom'>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
            <feOffset dx='-2' dy='-2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0965089598 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1'></feColorMatrix>
            <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter2'></feMorphology>
            <feOffset dx='7' dy='7' in='shadowSpreadOuter2' result='shadowOffsetOuter2'></feOffset>
            <feGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter2' result='shadowBlurOuter2'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.312144886 0' type='matrix' in='shadowBlurOuter2' result='shadowMatrixOuter2'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='shadowMatrixOuter2'></feMergeNode>
            </feMerge>
        </filter>
        <path id='pathBamboo1Top' d='M2.0250468e-13,32.2294169 L2.0250468e-13,134.073598 C2.0250468e-13,137.402723 2.81763565,140.102491 6.27408748,140.102491 L46.9244706,140.102491 C51.093887,140.102491 54.4761905,136.861594 54.4761905,132.861938 L54.4761905,8.26677221 C54.4761905,2.32022487 48.122251,-1.67354925 42.4356462,0.69095325 L13.2611394,12.8310855 C5.21890004,16.1837383 2.0250468e-13,23.806612 2.0250468e-13,32.2294169 Z'></path>
        <rect id='pathBamboo1Bottom' x='2.0250468e-13' y='148.323112' width='54.4761905' height='62.0274601' rx='4'></rect>
        <path id='pathBamboo2Top' d='M1.91846539e-13,31.39953 L1.91846539e-13,130.587728 C1.91846539e-13,133.830596 2.81734067,136.460412 6.27343065,136.460412 L46.9252612,136.460412 C51.094241,136.460412 54.4761905,133.303487 54.4761905,129.407462 L54.4761905,8.05203066 C54.4761905,2.2652885 48.1229162,-1.63073593 42.4369068,0.672502042 L13.2654543,12.4980821 C5.21265055,15.7638672 1.91846539e-13,23.1892314 1.91846539e-13,31.39953 Z'></path>
        <rect id='pathBamboo2Bottom' x='1.91846539e-13' y='144.250113' width='54.4761905' height='128.127919' rx='4'></rect>
        <path id='pathBamboo3Top' d='M2.27373675e-13,28.8672313 L2.27373675e-13,98.930886 C2.27373675e-13,101.386255 2.81763565,103.3791 6.27408748,103.3791 L46.9244706,103.3791 C51.093887,103.3791 54.4761905,100.986543 54.4761905,98.034391 L54.4761905,6.10082619 C54.4761905,1.71542384 48.122251,-1.23672852 42.4356462,0.510580223 L13.2611394,9.46411002 C5.21890004,11.9423192 2.27373675e-13,22.654578 2.27373675e-13,28.8672313 Z'></path>
        <rect id='pathBamboo3Middle' x='2.27373675e-13' y='111.649428' width='54.4761905' height='140.595576' rx='4'></rect>
        <rect id='pathBamboo3Bottom' x='1.15107923e-12' y='259.97254' width='54.4761905' height='62.0274601' rx='4'></rect>
        <path id='pathLeavesTop' d='M1.26757293,39.3136921 C14.4576534,18.6305321 39.2143283,10.895597 64.0483121,0.214019842 C66.0999703,-0.669972748 67.8186056,1.34734368 66.6887069,3.28532743 C54.56311,24.3028179 28.1413212,47.2979585 6.84570434,49.581606 C1.24378559,50.1652678 -1.9139839,44.3059836 1.26757293,39.3136921 Z'></path>
        <path id='pathLeavesBottom' d='M11.9939529,50.6936326 C27.1950293,46.3390929 41.7871347,56.13518 57.7421752,64.264956 C58.3378913,64.5670888 58.7047745,65.2515107 58.6635149,65.9837188 C58.6222553,66.7159268 58.1814686,67.343024 57.556584,67.5585241 C42.6860919,72.7266923 20.5021479,70.8130381 9.90605227,59.8388169 C8.62323097,58.4974641 8.09580819,56.4767649 8.53099602,54.5706048 C8.96618385,52.6644447 10.2944512,51.1773757 11.9939529,50.6936326 L11.9939529,50.6936326 Z'></path>
    </defs>
</svg>`

const svg = {
    defs,
};

export default svg;